
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-individual-client-listing-component",
  components: {},
  props: {
    route: String,
  },
  data() {
    return {
      clients: [
        {
          dateCreated: "",
          avatar: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          gender: { name: "" },
          isEnabled: false,
          policies: 0,
          publicId: "",
          firstName: "",
          profilePublicId: "",
          isLegacy: false,
        },
      ],
      avatarUrl: variables.AVATAR_ROUTE,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.getClientsList();

    //Set page title
    document.title = "Individual Client List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientsList() {
      //Fetch list of client
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.route}`)
          .then(({ data }) => {
            //Assign data to props
            this.clients = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    deleteStaff(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.SUPERADMIN_USER_ROUTE}/${publicId}`)
          .then(({ data }) => {
            variables.toastAlert(data.message, "success");
            this.getClientsList();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    toggleStatus(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(
          `${variables.SUPERADMIN_USER_STATUS_ROUTE}/${publicId}`,
          []
        )
          .then(({ data }) => {
            variables.toastAlert(data.message, "success");
            this.getClientsList();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    activateProfile(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`${variables.LEGACY}/${publicId}/activate`, {})
          .then(({ data }) => {
            variables.toastAlert(data.message, "success");
            this.getClientsList();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
